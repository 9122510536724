<div class="container-fluid">
    <div class="card primary-card">
        <div>
            <div class="row p-4">
                <h3 class="card-title"><b>Capture</b> Client</h3>
            </div>
            <!-- <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs>
            <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs>
                <mat-tab #clientAffordability [disabled]="!clientAffordability.isActive"  label="Client Affordability">
                    <app-client-affordability (formSubmitted)="onFormSubmitted($event)" (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                    </app-client-affordability>
                </mat-tab>
                <mat-tab #clientDetails [disabled]="!clientDetails.isActive" label="Client Details">
                    <app-personal-details [clientAffordabilityDataEvent]="formDataEvent" (updateStepEvent)="updateStep()"></app-personal-details>
                </mat-tab>
                 <mat-tab #bankEmployerDetails [disabled]="!bankEmployerDetails.isActive" label="Bank and Employment Details">
                    <app-bank-employer-details (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                    </app-bank-employer-details>
                </mat-tab>
                <mat-tab #uploadDocuments [disabled]="!uploadDocuments.isActive" label="Upload Client Documents">
                    <app-upload-documents (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-upload-documents>
                </mat-tab>
                <mat-tab #finished [disabled]="!finished.isActive" label="Finished">
                    <app-client-capture-final (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-client-capture-final>
                </mat-tab>
            </mat-tab-group> -->

            <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs (selectedTabChange)="onTabChange($event)">
                <mat-tab #loanApplication [disabled]="!loanApplication.isActive"  label="Loan Application">
                    <app-loan-application  (updateStepEvent)="updateStep()"></app-loan-application>
                </mat-tab>

                <mat-tab #clientDetails [disabled]="!clientDetails.isActive"  label="Client Details">
                    <app-personal-details  [clientAffordabilityDataEvent]="formDataEvent" (updateStepEvent)="updateStep()"></app-personal-details>
                </mat-tab>

                <mat-tab #clientAddress [disabled]="!clientAddress.isActive" label="Client Address">
                    <app-client-address (formSubmitted)="onFormSubmitted($event)" (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                    </app-client-address>
                </mat-tab>
                
                <mat-tab #clientAffordability [disabled]="!clientAffordability.isActive" label="Client Affordability">
                    <app-client-affordability (formSubmitted)="onFormSubmitted($event)" (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                    </app-client-affordability>
                </mat-tab>

                <mat-tab #bankEmployerDetails  [disabled]="!bankEmployerDetails.isActive" label="Bank and Employment Details">
                    <app-bank-employer-details (updateStepEvent)="updateStep()">
                    </app-bank-employer-details>
                </mat-tab>
                <mat-tab #uploadDocuments [disabled]="!uploadDocuments.isActive"  label="Upload Client Documents">
                    <app-upload-documents (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-upload-documents>
                </mat-tab>
                <mat-tab #finished [disabled]="!finished.isActive"  label="Finished">
                    <app-client-capture-final (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-client-capture-final>
                </mat-tab>
            </mat-tab-group>

            <!--UAT coding below -->

            <!-- <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs (selectedTabChange)="onTabChange($event)">
                <mat-tab #loanApplication  label="Loan Application">
                    <app-loan-application  (updateStepEvent)="updateStep()"></app-loan-application>
                </mat-tab>

                <mat-tab #clientDetails  label="Client Details">
                    <app-personal-details  [clientAffordabilityDataEvent]="formDataEvent" (updateStepEvent)="updateStep()"></app-personal-details>
                </mat-tab>

                <mat-tab #clientAddress label="Client Address">
                    <app-client-address (formSubmitted)="onFormSubmitted($event)" (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                    </app-client-address>
                </mat-tab>
                
                <mat-tab #clientAffordability label="Client Affordability">
                    <app-client-affordability (formSubmitted)="onFormSubmitted($event)" (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                    </app-client-affordability>
                </mat-tab>

                <mat-tab #bankEmployerDetails label="Bank and Employment Details">
                    <app-bank-employer-details (updateStepEvent)="updateStep()">
                    </app-bank-employer-details>
                </mat-tab>
                <mat-tab #uploadDocuments label="Upload Client Documents">
                    <app-upload-documents (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-upload-documents>
                </mat-tab>
                <mat-tab #finished label="Finished">
                    <app-client-capture-final (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                    </app-client-capture-final>
                </mat-tab>
            </mat-tab-group> -->
        </div>
    </div>
</div>
import { AdminLayoutComponent } from './layouts/admin-portal/admin-layout/admin-layout.component';
import { AuthenticateAdminLayoutComponent } from './layouts/admin-portal/authenticate-admin-layout/authenticate-admin-layout.component';
import { AuthenticateClientLayoutComponent } from './layouts/client-portal/authenticate-client-layout/authenticate-client-layout.component';
import { ClientLayoutComponent } from './layouts/client-portal/client-layout/client-layout.component';

import { BrokerLayoutComponent } from './layouts/broker-portal/broker-layout/broker-layout.component';
import { AuthenticateBrokerLayoutComponent } from './layouts/broker-portal/authenticate-broker-layout/authenticate-broker-layout.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { CancelledPageComponent } from './cancelled-page/cancelled-page.component';
import { NotifyPageComponent } from './notify-page/notify-page.component';

let routes: Routes = [
  {
    path: 'client',
    redirectTo: 'client/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    redirectTo: 'admin/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'broker',
    redirectTo: 'broker/auth/login/',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthenticateBrokerLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/broker-portal/authenticate-broker-layout/authenticate-broker-layout.module').then(m => m.AuthenticateBrokerLayoutModule)
    }]
  },
  {
    path: 'broker/',
    redirectTo: 'broker/auth/login/',
    pathMatch: 'full',
  },
  {
    path: 'client/auth',
    component: AuthenticateClientLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/client-portal/authenticate-client-layout/authenticate-client-layout.module').then(m => m.AuthenticateClientLayoutModule)
    },
    ]
  },
  {
    path: 'admin/auth',
    component: AuthenticateAdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/admin-portal/authenticate-admin-layout/authenticate-admin-layout.module').then(m => m.AuthenticateAdminLayoutModule)
    },
    ]
  },
  {
    path: 'broker/auth',
    component: AuthenticateBrokerLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/broker-portal/authenticate-broker-layout/authenticate-broker-layout.module').then(m => m.AuthenticateBrokerLayoutModule)
    },
    ]
  },
  { 
    path: 'client',
    component: ClientLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/client-portal/client-layout/client-layout.module').then(m => m.ClientLayoutModule)
    }]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/admin-portal/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  },
  {
    path: 'broker',
    component: BrokerLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/broker-portal/broker-layout/broker-layout.module').then(m => m.BrokerLayoutModule)
    }]
  },
  {
    path: 'failed',
    component: ErrorPageComponent,

  },
  {
    path: 'success',
    component: SuccessPageComponent,

  },
  {
    path: 'cancelled',
    component: CancelledPageComponent,

  },
  {
    path: 'notify',
    component: NotifyPageComponent,

  },

  
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [
  ],
})



export class AppRoutingModule {

  ngOnInit() {

    routes = [
      {
        path: '',
        redirectTo: 'broker',
        pathMatch: 'full',
      },
      {
        path: 'auth',
        component: AuthenticateAdminLayoutComponent,
        children: [{
          path: '',
          loadChildren: () => import('./layouts/admin-portal/authenticate-admin-layout/authenticate-admin-layout.module').then(m => m.AuthenticateAdminLayoutModule)
        },
        ]
      },
      {
        path: '',
        component: AdminLayoutComponent,
        children: [{
          path: '',
          loadChildren: () => import('./layouts/admin-portal/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
        }]
      },

    ];
  }

}
<div class="container-fluid">
    <div class="form-position">
        <div style="padding-top: 20px">
            <h4><b>Bank</b> Details</h4>
        </div>
        <div>
            <app-bank-details (sendBankDetailsResult)="getBankDetailsResult($event)" (bankForm)="isBankFormValid($event)"></app-bank-details>
        </div>
        <div>
            <h4><b>Employer</b> Details</h4>
        </div>
        <div>
            <app-employer-details (sendEmployerDetailsResult)="getEmployerDetailsResult($event)" (employerForm)="isEmployerFormValid($event)"></app-employer-details>
        </div>
        <div class="row row-padding">
            <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="save()"><i class="fa fa-arrow-right"></i></button>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="form-position">
        <form novalidate  [formGroup]="form" (submit)="onSubmit()">
            <div class="card-body col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Loan Amount</mat-label>
                            <input matInput class="mat-textbox" input name="loanAmount" min="500" max="120000" input type="number" formControlName="loanAmount" required autocomplete="nope" (change)="calculateLoanAmount()">
                            <mat-error>
                              Please enter a loan amount between R500 - R4 000
                            </mat-error>
                          </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Gross Salary as per Payslip</mat-label>
                            <input matInput class="mat-textbox" input name="grossSalary" min="1" input type="number" formControlName="grossSalary" required autocomplete="nope" (change)="calculateMinNCRLivingExpenses($event.target.value)">
                            <mat-error>
                              Please enter a valid Amount.
                            </mat-error>
                          </mat-form-field>
                    </div>
                    
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Net Salary
                            </mat-label>
                            <input matInput class="mat-textbox" input name="nettSalary" min="1" input type="number" formControlName="netSalary" required autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Medical Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="medicalExpenses"  input type="number" formControlName="medicalExpenses" required autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Housing Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="housingExpenses" input type="number" formControlName="housingExpenses" required autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Education Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="educationExpenses" input type="number" formControlName="educationExpenses" required autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Consumables Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="consumablesExpenses" input type="number" formControlName="consumablesExpenses" required autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Water Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="waterExpenses" input type="number" formControlName="waterExpenses" required autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Transport Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="transportExpenses" input type="number" formControlName="transportExpenses" required autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Other Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="otherExpenses" input type="number" formControlName="otherExpenses" autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>
<!-- 
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">NCR Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="ncrExpenses" input type="number" formControlName="ncrExpenses" autocomplete="nope" (change)="calculateTotalExpenses()">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div> -->

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Total Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="totalExpenses" input type="number" formControlName="totalExpenses" autocomplete="nope">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <br>
                <!-- <label><b>Totals</b></label>
                <br>
                <div class="row well">
                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">NCR Min Living Expenses
                            </mat-label>
                            <input matInput class="mat-textbox" input name="ncrMinLivingExpenses" input type="text" formControlName="ncrMinLivingExpenses" disabled>
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Min Required Take Home
                            </mat-label>
                            <input matInput class="mat-textbox" input name="minRequiredTakeHome" input type="text" formControlName="minRequiredTakeHome" autocomplete="nope">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Disposable Income
                            </mat-label>
                            <input matInput class="mat-textbox" input name="disposableIncome" input type="text" formControlName="disposableIncome" autocomplete="nope">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Max NCR Allowed Installment
                            </mat-label>
                            <input matInput class="mat-textbox" input name="maxNCRAllowedInstl" input type="text" formControlName="maxNCRAllowedInstl" autocomplete="nope">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="standard" class="textbox shared-width">
                            <mat-label class="mat-label-margin">Max Installment Allowed
                            </mat-label>
                            <input matInput class="mat-textbox" input name="maxInstallmentAllowed" input type="text" formControlName="maxInstallmentAllowed" autocomplete="nope">
                            <mat-error>
                                Please enter a valid Amount.
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div> -->

            </div>
        </form>

    </div>
    <div class="row row-padding">
        <br>
        <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="save()" [disabled]="!form.valid"><i class="fa fa-arrow-right" ></i></button>
    </div> 
</div>        

  

export class SaveClientForCallback {
    name: string;
    surname: string;
    idnumber: string;
    cellnumber: string;
    email: string;
    staffId: number;
    isPassport: boolean;
    dateOfIssue: string;
    expiryDate: string;
    dateOfBirth: string; country: number;
    loanStatusId: number;
    subDomain: string;
    id:number;
}

export class FindClient {
    firstName: string;
    lastName: string;
    externalID: string;
    mobileNumber: string;
    email: string;
    staffId: number;
    isPassport: boolean;
    dateOfIssue: string;
    expiryDate: string;
    dateOfBirth: string;
    country: number;
    clientId: number;
    loanStatusId: number;
}

export class FindBank {
    AccountName: string;
    AccountNumber: string;
    AccountTypeCdAccountType: number;
    BankName: string;
    BanksCdBank: number;
    BranchNumber: string;
    ClientId: number;
}

export class FindEmployer {
    ClientId: number;
    Employer_Name: string;
    Employer_Contact_Number: string;
    Employee_Number: number;
    Employment_Start_Date: string;
    Employer_Salary_Date: string;
    Employee_Occupation: string;
    Gross_Salary: number;
    Net_Salary: number;
}

export class Consent {
    id: number;
    clientId: number;
    loanId: number;
    creditCheck: boolean;
    bankVerification: boolean;
    marketingMobile: boolean;
    marketingEmail: boolean;
    popi: boolean;
}

export class BankingDetails {
    account_Name: string;
    account_number: string;
    account_Type_cd_account_Type: number;
    bankName: string;
    banks_cd_Bank: number;
    branch_number: string;
    clientId: number;
    UpdateDetails: boolean;
}

export class Acknowledgments {
    Id: number;
    ClientId: number;
    StaffId: number;
    OlderThanThirtyDays: number;
    ReflectsThreeSalaries: number;
    BelongsToClient: number;
}

export class EmployerDetails {
    clientId: number;
    employer_Name: string;
    employer_Contact_Number: string;
    employee_Number: number;
    employment_Start_Date: string;
    employer_Salary_Date: string;
    employee_Occupation: string;
    gross_salary: number;
    net_salary: number;
    employerSector_cd_Employer_sector:number;
    employmentType_cd_Employment_Type:number;
    salary_Type_cd_Salary_Type: number;
  UpdateDetails: boolean;
}

export class SaveStaffId {
    staff_id: number;
}

export class ClientResult {
    clientId: string;
}

export class Document {

}

export class Report {
    staffId: number;
    idNumber: string;
    source: string;
}

export class clientAffordabilityData {
  ClientId: number;
  GrossSalary: number;
  NetSalary: number;
  Housing: number;
  Transport: number;
  Education: number;
  OtherExpenses: number;
  Medical: number;
  Consumables: number;
  Municipal: number;
  NcrExpenses: number;
}
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AdminBarGraph } from 'app/ts/models/broker-interfaces';
import * as Chart from 'chart.js';
import { environment } from 'environments/environment';

const API_URL = environment.apiURL;

@Component({
  selector: 'app-bar-chart-x',
  templateUrl: './bar-chart-x.component.html',
  styleUrls: ['./bar-chart-x.component.scss']
})
export class BarChartXComponent implements OnInit {

  data: any;
  url = `${API_URL}Portal/getPrevetDashboardData`;
  name = [];
  count = [];
  barchart = [];

  constructor(private http: HttpClient) { }
  
  n: AdminBarGraph;
  i: AdminBarGraph;
  f: AdminBarGraph;
  t: AdminBarGraph;
  e: AdminBarGraph;

  ngOnInit(): void {
    new Promise((resolve: any): any => {
      this.http.get(`${this.url}/${sessionStorage.getItem("staffId")}`, ).subscribe((d: any) => {
        resolve(d);
        
        this.n = d.prevetNull;
        this.i = d.prevetInvId;
        this.f = d.prevetFalse;
        this.t = d.prevetTrue;
        this.e = d.prevetError;

        this.barchart.push(new Chart('canvas', {
          type: 'horizontalBar',
          data: {
            datasets: [
              {
                data: [this.f.data],
                borderColor: "#CFD328",
                backgroundColor: [
                  "#CFD328"
                ],
                fill: true,
                label: this.f.label,
              },
              {
                data: [this.t.data],
                borderColor: "#042D4E",
                  backgroundColor: [
                    "#042D4E"
                  ],
                fill: true,
                label: this.t.label,
              },
              {
                data: [this.i.data],
                borderColor: "#E1341E" ,
                  backgroundColor: [
                    "#E1341E" 
                  ],
                fill: true,
                label: this.i.label,
              },
              {
                data: [this.e.data],
                borderColor: "#1ecbe1",
                backgroundColor: [
                  "#1ecbe1"
                ],
                fill: true,
                label: this.e.label,
              },
              {
                data: [this.n.data],
                borderColor: "#902dd2",
                  backgroundColor: [
                    "#902dd2"
                  ],
                fill: true,
                label:this.n.label == null ? "No Status" : this.n.label,
              },
            ]
          },
          options: {
            legend: {
              display: true
            },
            scales: {
              xAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true
                }
              }],
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true
                }
              }]
            },
            tooltips: {
              callbacks: {
                title: function() {
                  return 'Prevets';
                }
              }
            } 
          }
        }));
      })
    });
  }

  GetRandomColor() {
    var trans = '0.5';
    var color = 'rgba(';
    for (var i = 0; i < 3; i++) {
      color += Math.floor(Math.random() * 255) + ',';
    }
    color += trans + ')';
    return color;
  }
}


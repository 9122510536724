import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-client-capture-final',
  templateUrl: './client-capture-final.component.html',
  styleUrls: ['./client-capture-final.component.scss', '../../forms.shared.scss']
})
export class ClientCaptureFinalComponent implements OnInit {

  constructor() { }

  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();

  ngOnInit(): void {
  }

  finish() {
    this.updateStepEvent.emit()
  }

  home() {
    this.updateStepEvent.emit();
    //this.router.navigateByUrl('broker/dashboard');
  }

  
}

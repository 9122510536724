<div class="container-fluid">
    <div class="form-position">
<form [formGroup]="addressForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="street" input  type="text" placeholder="Street Name">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="addressLine1" input  type="text" placeholder="Address Line 1">
          <mat-icon matSuffix><i class="fas fa-home"></i></mat-icon>
        </mat-form-field>        
      </div>
    </div>
  
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="addressLine2" input  type="text" placeholder="Address Line 2">
          <mat-icon matSuffix><i class="fas fa-home"></i></mat-icon>
        </mat-form-field>        
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-select formControlName="addressTypeId" placeholder="Address Type">
            <mat-option *ngFor="let type of addressTypeIdOptions" [value]="type.id">{{ type.name }}</mat-option>            
          </mat-select>
          <mat-icon matSuffix><i class="fas fa-building"></i></mat-icon>
        </mat-form-field>        
      </div>
    </div>
  
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="city" placeholder="City" input  type="text">
          <mat-icon matSuffix><i class="fas fa-map-pin"></i></mat-icon>
        </mat-form-field>       
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-select formControlName="stateId" placeholder="Provice">
            <mat-option *ngFor="let type of StateOptions" [value]="type.id">{{ type.name }}</mat-option>            
          </mat-select>
          <mat-icon matSuffix><i class="fas fa-map-marker"></i></mat-icon>
        </mat-form-field>     
      </div>
    </div>

  
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <input matInput formControlName="postalCode" placeholder="Postal Code" input  type="text">
          <mat-icon matSuffix><i class="fas fa-signs-post"></i></mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-select formControlName="countryId" placeholder="Country">
            <mat-option *ngFor="let type of countryIdOptions" [value]="type.id">{{ type.name }}</mat-option>            
          </mat-select>
          <mat-icon matSuffix><i class="fas fa-flag"></i></mat-icon>
        </mat-form-field>
      </div>

    </div>
    
    <div class="row row-padding">
        <br>
        <button mat-fab class="form-button-right" style="box-shadow: none;" type="submit" (click)="save()" [disabled]="!addressForm.valid" ><i class="fa fa-arrow-right" ></i></button>
    </div>
  </form>
  </div>
  </div>
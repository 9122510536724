<form [formGroup]="empDetails" novalidate>
    <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employer Name</mat-label>
                <input matInput class="mat-textbox" input name="empName" input type="text" formControlName="employer_Name" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employer Contact No</mat-label>
                <input matInput class="mat-textbox" input name="empContactNo" input type="text" formControlName="employer_Contact_Number" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employee No</mat-label>
                <input matInput class="mat-textbox" input name="empNo" input type="text" formControlName="employee_Number" autocomplete="nope">
            </mat-form-field>
        </div>
    </div>
    <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Date Started</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="employment_Start_Date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              
                <mat-error *ngIf="empDetails.get('employment_Start_Date').hasError('required')">
                  Date Started is required.
                </mat-error>
                <mat-error *ngIf="empDetails.get('employment_Start_Date').hasError('startDateOutOfRange')">
                    Date Started must be strictly less than 3 months ago and not greater than today.
                </mat-error>
              </mat-form-field>
        </div>
        <!-- <div class="col" *ngIf="!EditProfile">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Gross Salary</mat-label>
                <input matInput class="mat-textbox" input name="Gross" input type="number" formControlName="gross_salary" autocomplete="nope">
            </mat-form-field>
        </div>
        <div class="col" *ngIf="!EditProfile">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Net Salary</mat-label>
                <input matInput class="mat-textbox" input name="Net" input type="number" formControlName="net_salary" autocomplete="nope">
            </mat-form-field>
        </div> -->
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Salary Date</mat-label>
                <mat-select formControlName="employer_Salary_Date">
                    <mat-option *ngFor="let date of salaryDates" [value]="date">
                        {{date}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employment Type</mat-label>
                <mat-select formControlName="employmentType_cd_Employment_Type">
                    <mat-option *ngFor="let type of employementType" [value]="type.id">
                        {{type.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Employment Sector</mat-label>
                <mat-select formControlName="employerSector_cd_Employer_Sector">
                    <mat-option *ngFor="let type of employmentSector" [value]="type.id">
                        {{type.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label class="mat-label-margin">Salary Type</mat-label>
                <mat-select formControlName="salary_Type_cd_Salary_Type">
                    <mat-option *ngFor="let type of salaryType" [value]="type.id">
                        {{type.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>
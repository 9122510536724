import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalDetailsComponent } from 'app/forms/broker-portal/personal-details/personal-details.component';

@Component({
  selector: 'app-client-update',
  templateUrl: './client-update.component.html',
  styleUrls: ['./client-update.component.scss']
})
export class ClientUpdateComponent implements OnInit {
  selectedIndex: number;
  clientId: number;
  router: any;
  route: any;
  @ViewChild('clientDetails') clientDetails: PersonalDetailsComponent;
  formDataEvent: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
    
  }

  onFormSubmitted(formData: FormGroup) {
    // Pass the form data to PersonalDetailsComponent
    //this.clientDetails.receiveFormData(formData);
    this.formDataEvent.emit(formData);
  }

  public previousStep() {
    this.selectedIndex -= 1;
  }

  updateStep() {
    
    if (this.selectedIndex == 5) {
      this.reload();
    }
    else {
      this.selectedIndex += 1;
    } 
  }

  onChange() {
    this.clientId = Number(sessionStorage.getItem("clientId"));
  }

  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./'], { relativeTo: this.route });
  }

}

<form>
    <div class="row">
        <div class="col-12">
            <h3>Log In</h3>
        </div>
        <div class="col-12">
            <mat-form-field appearance="standard">
                <mat-label>Cell Number</mat-label>
                <input matInput required intput name="mobilenumber" input type="text" [(ngModel)]="credentials.mobilenumber" (ngModelChange)="trimSpaces()">
                <mat-icon matSuffix><i class="fa fa-phone"></i></mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12 py-0 my-1">
            <button type="submit" class="button sign-in-button flex card card-body mat-textbox" (click)="signIn()">
                <h3 class="button-text">
                    <b>Send OTP</b>
                </h3>
            </button>
        </div>

    </div>
</form>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AdminBarGraph } from 'app/ts/models/broker-interfaces';
import * as Chart from 'chart.js';
import { environment } from 'environments/environment';

const API_URL = environment.apiURL;

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})

export class LineChartComponent implements OnInit {

  constructor(private http: HttpClient) { }

  url = `${API_URL}Portal/getSalesDashboardData`;
  barchart1 = [];

  setOne: AdminBarGraph;
  setTwo: AdminBarGraph;
  setThree: AdminBarGraph;
  isAdmin: boolean;
  staffId: number;

  ngOnInit(): void {
    new Promise((resolve: any): any => {
      this.staffId = Number(sessionStorage.getItem("staffId"));
      this.isAdmin = JSON.parse(sessionStorage.getItem("isSuper"));

      if(this.isAdmin) {
        this.staffId = 0;
      }

      this.http.get(`${this.url}/${this.staffId}`).subscribe((d: any) => {
        resolve(d);
        this.setOne = d.firstSalesSet;
        this.setTwo = d.secondSalesSet;
        this.setThree = d.thirdSalesSet;

        if (this.setOne.data != 0 || this.setTwo.data != 0 || this.setThree.data != 0) {
          this.barchart1.push(new Chart('canvas1', {
            type: 'bar',
            data: {
              datasets: [
                {
                  data: [this.setOne.data],
                  borderColor: "#CFD328",
                  backgroundColor: [
                    "#CFD328"
                  ],
                  fill: true,
                  label: this.setOne.label,
                },
                {
                  data: [this.setTwo.data],
                  borderColor: "#042D4E",
                  backgroundColor: [
                    "#042D4E"
                  ],
                  fill: true,
                  label: this.setTwo.label,
                },
                {
                  data: [this.setThree.data],
                  borderColor: "#1ECBE1" ,
                  backgroundColor: [
                    "#1ECBE1" 
                  ],
                  fill: true,
                  label: this.setThree.label,
                }
              ]
            },
            options: {
              legend: {
                display: true,
              },
              scales: {
                xAxes: [{
                  display: true,
                  ticks: {
                    beginAtZero: true
                  }
                }],
                yAxes: [{
                  display: true,
                  ticks: {
                    beginAtZero: true
                  }
                }]
              },
              tooltips: {
                callbacks: {
                  title: function () {
                    return 'Sales';
                  }
                }
              }
            }

          }));
        }
      });
    })
  }

  GetRandomColor() {
    var trans = '0.5';
    var color = 'rgba(';
    for (var i = 0; i < 3; i++) {
      color += Math.floor(Math.random() * 255) + ',';
    }
    color += trans + ')';
    return color;
  }
}

<div class="row">
    <div class="container-fluid">
        <div class="card primary-card">
            <div>
                <div class="row p-4">
                    <h3 class="card-title"><b>Client</b> Profile</h3>
                </div>
    
                <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs>
                    <!-- <mat-tab #clientAffordability label="Client Affordability">
                        <app-client-affordability (formSubmitted)="onFormSubmitted($event)" (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                        </app-client-affordability>
                    </mat-tab> -->
                    <mat-tab #clientDetails label="Client Details">
                        <app-personal-details  [clientAffordabilityDataEvent]="formDataEvent" (updateStepEvent)="updateStep()"></app-personal-details>
                    </mat-tab>
                    <mat-tab #clientAddress label="Client Address">
                        <app-client-address (formSubmitted)="onFormSubmitted($event)" (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                        </app-client-address>
                    </mat-tab>
                    <mat-tab #bankEmployerDetails label="Bank and Employment Details">
                        <app-bank-employer-details (updateStepEvent)="updateStep()">
                        </app-bank-employer-details>
                    </mat-tab>
                    <mat-tab #uploadDocuments label="Documents">
                        <app-upload-documents (updateStepEvent)="updateStep()" (previousStepEvent)="previousStep()">
                        </app-upload-documents>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'app/data-sharing-service';
import { ApiService } from 'app/ts/ApiService';
import { Products } from 'app/ts/models/broker-interfaces';
import { loanAmountRates } from 'app/ts/models/loan-classes';
import { ProductSelectService } from 'app/ts/services/product-select.service';

@Component({
  selector: 'app-loan-application',
  templateUrl: './loan-application.component.html',
  styleUrls: ['./loan-application.component.scss', '../../forms.shared.scss']
})
export class LoanApplicationComponent implements OnInit {
  @Output() updateStepEvent = new EventEmitter<number>();
  loanForm:FormGroup;
  staffId: any;
  productId: any;
  Products : Products[];
  minmumTerm: number = 1;
  maxmumTerm: number = 24;
  monthlyPayment: number= 0;
  selectedProduct: Products;
  loanAmount:any;
  totalInterestFixed: string;
  totalFees = 0;
  totalFeesFixed: string;
  totalRepayable = 0;
  totalRepayableFixed: string;
  thumbLabel = false;
  vertical = false;
  tickInterval = 1;
  isLoggedIn = false;
  emiVal: string;
  public loanRates: loanAmountRates[] = [];
  totalInterest: number;
  clientToken: string;
  clientDetails: any;
  
  constructor(private route: ActivatedRoute,private api: ApiService,    public _productSelect: ProductSelectService,     private formBuilder: FormBuilder,private dataSharingService: DataSharingService, private router: Router,) { }

  ngOnInit(): void {
    
    this.route.paramMap.subscribe(params => {
      this.clientToken = params.get('token');
    });

    this.processForm();
    this.loadInitiations();

  }

  processForm()
  {
    
    this.loanForm = this.formBuilder.group({
      loanterm:['', Validators.required],
      loanamount: [{ value: '', disabled: true }, Validators.min(500)],
    });

    this.getClientDetails();

  }

  getClientDetails()
  {

    this.api.getClientUsingToken(this.clientToken).subscribe(
      (data: any) => {
        if(data == "Invliad Token.")
        {
          this.router.navigate(['failed',]);
        }

        sessionStorage.setItem("ClientTokenDetails", JSON.stringify(data));
        this.clientDetails = data;
        
        

        this.getLoanAffordability();
      },
      (error: any) => {
        //kick user out
        
        this.router.navigate(['/failed',]);
      }
    );
    
  }

  getClientInfo(mobileNumber: string , data: any) {
    this.api.GetFullClientInfo(mobileNumber).subscribe((data: any) => {
      if(data != null)
      {
        var json = JSON.stringify(data);
        sessionStorage.setItem('ClientInfo', json);
        sessionStorage.setItem('clientId', data.client.id.toString());
      }else{

      }

    })
  }


  getLoanAffordability() {
    const ClientTokenDetails = JSON.parse(sessionStorage.getItem("ClientTokenDetails"));

    this._productSelect
    .getStaffProductList(ClientTokenDetails.staffId)
    .subscribe((data: Products[]) => {
      //maxNumberOfRepayments
     
      this.loanAmount = ClientTokenDetails.totalIncl;
      this.Products = data;
      
      //const selectedProducts = this.findSelectedProducts(data);

      this.Products = this.Products.filter((item) => ClientTokenDetails.totalIncl >= item.minPrincipal && ClientTokenDetails.totalIncl <= item.maxPrincipal);

      this.loanForm.patchValue({
          loanterm: this.Products[0].id,
          loanamount: ClientTokenDetails.totalIncl,
        });
    });
  }

  findSelectedProducts(data: Products[]): Products | undefined {
    const ClientTokenDetails = JSON.parse(sessionStorage.getItem("ClientTokenDetails"));
    return data.find((item) =>
      ClientTokenDetails.totalIncl >= item.minPrincipal && ClientTokenDetails.totalIncl <= item.maxPrincipal
    );
  }

  formatLabel(value: number): string {
    if (value >= 1) {
      return `${value}`;
    }

    return `${value}`;
  }

   PMT(rate, nper, pv, fv, type) {
    if (!fv) fv = 0;
    if (!type) type = 0;

    if (rate == 0) return -(pv + fv) / nper;

    var pvif = Math.pow(1 + rate, nper);
    var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

    if (type == 1) {
      pmt /= 1 + rate;
    }

    return pmt;
  }

  onLoanTermSelected(selectedProductId: number): void {
    // Find the selected product object based on the selectedProductId
    const selectedProduct = this.Products.find(product => product.id === selectedProductId);

    // Do something with the selected product, for example, store it in a variable
    this.selectedProduct = selectedProduct;
    
    this.monthlyPayment = Number(this.calculateMonthlyPayment());
  }


 calculateMonthlyPayment() {

    if (this.loanRates !== undefined && this.loanRates !== null && this.loanRates.length > 0) {
      var _loanAmount = Number(this.loanAmount);
      var selectedLoanAmount = (_loanAmount + this.initiationFee(_loanAmount,this.selectedProduct.maxNumberOfPayments));
      var numberOfMonths = this.selectedProduct.maxNumberOfPayments;
      var rateOfInterest = this.selectedProduct.nominalInterestRate;
      var monthlyInterestRatio = (rateOfInterest / 100) / 12;
      var creditLife = (_loanAmount / 1000) * 5.5;
      var totalCreditLife = creditLife * numberOfMonths;
      var top = Math.pow((1 + monthlyInterestRatio), numberOfMonths);
      var bottom = top - 1;
      var sp = top / bottom;
      this.emiVal = (((selectedLoanAmount * monthlyInterestRatio) * sp) + 69 + creditLife).toFixed(2);
      this.totalRepayable = (((selectedLoanAmount * monthlyInterestRatio) * sp) + 69 + creditLife) * this.selectedProduct.maxNumberOfPayments;
      this.totalInterest = this.totalRepayable - this.totalFees - this.loanAmount - totalCreditLife;
      this.totalRepayableFixed = ((((selectedLoanAmount * monthlyInterestRatio) * sp) + 69) * this.selectedProduct.maxNumberOfPayments).toFixed(2);
      this.totalInterestFixed = (this.totalRepayable - this.totalFees - this.loanAmount).toFixed(2);

      return this.emiVal.toLocaleString();
    }
  }

  loadInitiations() {
    if (sessionStorage.getItem("LoanRates") === null) {
      this.api.getInitiations().subscribe((data: loanAmountRates[]) => {
        this.loanRates = data as loanAmountRates[];
        sessionStorage.setItem("LoanRates", JSON.stringify(this.loanRates));
      });
    }
    else {
      this.loanRates = JSON.parse(sessionStorage.getItem("LoanRates")) as loanAmountRates[];
    }
  }

  
  initiationFee(value: number,term) {
    if (value >= 10000) {
      this.totalFees = 1207.50 + (69 * term);
      this.totalFeesFixed = this.totalFees.toFixed(2);
      return 1207.50;
    }
    else {
      try {
        // Attempt to find the exact value in this.loanRates
        let exactLoanRate = this.loanRates.find(s => s.loan_amount == value);
      

        this.totalFees = exactLoanRate.initiation + (69 * term);
        this.totalFeesFixed = this.totalFees.toFixed(2);
        return exactLoanRate.initiation;
      } catch (error) {
        let targetValue = value;
        this.loanRates.sort((a, b) => Math.abs(a.loan_amount - targetValue) - Math.abs(b.loan_amount - targetValue));
        let closestLoanRate = this.loanRates[0];
      
        this.totalFees = closestLoanRate.initiation + (69 * term);
        this.totalFeesFixed = this.totalFees.toFixed(2);
        return closestLoanRate.initiation;
      }
    }
  }

  

  save()
  {
    this.dataSharingService.setSelectedProduct( this.selectedProduct);
    this.dataSharingService.setClientDetails(this.clientDetails);
    sessionStorage.setItem("ClientTokenDetails", JSON.stringify(this.clientDetails));
    this.updateStepEvent.emit();
  }
  
}
